import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <div className='not-found'>
            <div className='inn'>
                <h1>404</h1>
                <p>Page not found. Maybe I changed or removed the file without updating links somewhere
                    else or... who knows what happened, there're millions of possibilities.</p>
                <p>Why don't you just go back to main page and check what's new?</p>
                <Link className="hr-btn" to="/">Go Home</Link>
            </div>
        </div>
    )
}
