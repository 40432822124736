import React from 'react'
import { Routes, Route } from 'react-router-dom'

import './assets/bg.jpg'
import './css/App.css'

import Home from './pages/home'
import Skills from './pages/skills'
import Portfolio from './pages/portfolio'
import Contact from './pages/contact'
import Apps from './pages/apps'
import NotFound from './pages/404'
import Gunel1 from './pages/apps/gunel1/gunel1'


function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/apps" element={<Apps />} />
        <Route path="/apps/gunel1" element={<Gunel1 />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App