import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import beep1 from './../sounds/beep1.mp3';
import adventure1 from './../sounds/adventure1.mp3';



export default function MyEffects() {

    // for click sound
    useEffect(() => {
        const beep = new Audio(beep1);
        beep.volume = 0.3;

        const playClick = () => {
            beep.play();
        };

        window.addEventListener('click', playClick);

        return () => {
            window.removeEventListener('click', playClick);
        }
    }, []);


    // for Adventure mode
    const [advMode, setAdvMode] = useState(false);

    const toggleAdv = () => {
        let curr_advMode = advMode;
        setAdvMode(!curr_advMode);
        (!advMode) ? startAdventure() : stopAdventure();
    }

    const adventure = useRef(new Audio(adventure1));
    adventure.current.volume = 0.3;

    const startAdventure = () => {
        adventure.current.play();
        document.body.classList.add('adventure');
    }

    const stopAdventure = () => {
        adventure.current.pause();
        adventure.current.currentTime = 0;
        document.body.classList.remove('adventure');
    }

    // Terminal focus
    const terminalData = useRef()
    const terminalInput = useRef()
    useEffect(() => {
        const focusTerminal = (e) => {
            if (e.target.closest("#adv-term")) terminalInput?.current?.focus()
        }

        window.addEventListener('click', focusTerminal)

        return () => {
            window.removeEventListener('click', focusTerminal)
        }
    }, [])


    // On Enter press in terminal
    const handleCommand = (event) => {
        if (event.key === 'Enter') {
            let command = terminalInput.current.value
            if (command === "") terminalData.current.innerHTML += '<div>muba00.com:~ guest$ </div>';
            else if (command === "clear") {
                terminalData.current.innerHTML = "";
                terminalInput.current.value = "";
                terminalInput.current.focus();
            }
            else if (command === "exit") {
                toggleAdv();
                terminalInput.current.value = "";
            }
            else {
                axios.get("https://muba00.com/api/terminal.php?c=" + terminalInput.current.value)
                    .then((response) => {
                        terminalData.current.innerHTML += '<div>muba00.com:~ guest$ <span style="color: white;">' + response.data.command + '</span></div>';
                        terminalData.current.innerHTML += '<div>' + response.data.answer + '</div>';
                        terminalInput.current.value = "";
                        terminalInput.current.focus();
                    })
                    .catch(error => console.error(error));
            }
        }
    }



    return (
        <>
            <div className="adv-mode">
                <button className="hr-btn" onClick={toggleAdv}>{(advMode) ? <i className="far fa-pause-circle"></i> : <i className="far fa-play-circle"></i>} Adventure Mode</button>
            </div>
            {(advMode) ?
                <div id="adv-term" className="adv-term">
                    <div className="adv-term-h">
                        <span className="adv-term-mac" style={{ backgroundColor: '#FF605C' }} onClick={toggleAdv}></span>
                        <span className="adv-term-mac" style={{ backgroundColor: '#FFBD44' }}></span>
                        <span className="adv-term-mac" style={{ backgroundColor: '#00CA4E' }}></span>
                    </div>
                    <div className="adv-term-in">
                        <div ref={terminalData}></div>
                        <span>muba00.com:~ guest$ </span>
                        <input type="text" ref={terminalInput} onKeyDown={handleCommand} className="adv-term-input" spellCheck="false" />
                    </div>
                </div> : null
            }
        </>
    )
}
