import React from 'react'
import { Link } from 'react-router-dom';
import pic from './../assets/pic.jpg';
import picAdv from './../assets/pic-adv.jpg'


export default function NavMenu() {
    return (
        <div className="i-left">
            <Link className="il-tpl" to="/">
                <div className="il-top">
                    <div className="il-img-c">
                        <img className="il-img-adv" src={picAdv} alt="" />
                        <img className="il-img" src={pic} alt="" />
                    </div>
                    <div>
                        <span className="il-name">Mubariz Hajimuradov</span><br />
                        <span className="il-title">Full Stack Web Developer</span>
                    </div>
                </div>
            </Link>
            <nav>
                <ul>
                    <li><Link to="/portfolio">Portfolio</Link></li>
                    <li><Link to="/skills">Skills</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="/apps">Apps</Link></li>
                </ul>
            </nav>
        </div>
    )
}
