
import React from "react"
import close from './../assets/close.svg'

export default function ImageView({ src, alt, style }) {

    const [isOpen, setIsOpen] = React.useState(false)

    const open = (e) => {
        e.preventDefault()
        setIsOpen(true)
    }

    return (
        <>
            <a href={src} onClick={open} >
                <img className="pr-img" src={src} alt={alt} loading="lazy" />
            </a>

            {isOpen && (
                <div className="imagePop">
                    <div className="close" onClick={() => { setIsOpen(false) }}>
                        <img src={close} alt="close" />
                    </div>
                    <div className="imageScroll">
                        <img style={style} src={src} alt={alt} loading="lazy" />
                    </div>
                </div>
            )}
        </>
    )
}
