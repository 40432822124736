import React from 'react'
import NavMenu from '../components/NavMenu'
import MyEffects from '../components/MyEffects'
import ImageView from '../components/ImageView'

import eal1 from './../screenshots/screencapture-root1-2019-04-21-22_50_03.png'
import eal2 from './../screenshots/screencapture-root1-login-2019-04-21-22_58_44.png'
import eal3 from './../screenshots/screencapture-root1-product-33-2019-04-21-22_57_57.png'
import eal4 from './../screenshots/screencapture-root1-register-2019-04-21-22_59_24.png'
import eal5 from './../screenshots/screencapture-root1adm-2019-04-21-23_03_55.png'
import eal6 from './../screenshots/screencapture-root1adm-login-2019-04-21-23_00_41.png'
import eal7 from './../screenshots/screencapture-root1adm-order-2019-04-21-23_06_59.png'

import aqs1 from './../screenshots/screencapture-aquasol-az-index-php-2019-04-21-22_39_15.png'
import aqs2 from './../screenshots/screencapture-aquasol-az-product-php-2019-04-21-22_40_03.png'
import aqs3 from './../screenshots/screencapture-aquasol-az-contact-php-2019-04-21-22_40_46.png'

import bruns1 from './../screenshots/bruns_menke_de.jpg'
import bruns2 from './../screenshots/bruns_menke_de2.jpg'
import bruns3 from './../screenshots/bruns_menke_de3.jpg'

import hoelscher1 from './../screenshots/hoelscher_menke_de.png'
import hoelscher2 from './../screenshots/hoelscher_menke_de2.png'
import hoelscher3 from './../screenshots/hoelscher_menke_de3.png'

import musa1 from './../screenshots/musa-app.webp'
import musa2 from './../screenshots/musa-app2.webp'
import musa3 from './../screenshots/musa-app3.webp'

import zhara1 from './../screenshots/zharaevents/Desktop.png'
import zhara2 from './../screenshots/zharaevents/Concerts.png'
import zhara3 from './../screenshots/zharaevents/History.png'
import zhara4 from './../screenshots/zharaevents/About_us.png'
import zhara5 from './../screenshots/zharaevents/iPhone_Home.png'
import zhara6 from './../screenshots/zharaevents/iPhone_History.png'



export default function Portfolio() {

    return (
        <>
            <NavMenu />


            <div className="i-right">
                <div className="ir-inner">
                    <h1 className="ir-h">PORTFOLIO</h1>
                    <p className="">Here're some projects I have worked on. For most of the projects all Design, Development of front-end and back-end, SEO and Deployment of below projects were completely done by me.</p>
                    <br /><br />


                    <ul className="pr-c">

                        <li>
                            <div className="pr-tm">
                                <span className="pr-dot"></span>
                                2023
                            </div>
                            <div className="pr-cn">
                                <span className="pr-title" href="#">Zhara Events - Website</span><br />
                                <span>Front-end development based on given design, back-end architecture based on required functionality.</span>
                                <br /><br />
                                <b>Technologies</b><br />
                                <span className="p-tech">HTML5</span>
                                <span className="p-tech">CSS3</span>
                                <span className="p-tech">Bootstrap 5</span>
                                <span className="p-tech">PHP</span>
                                <span className="p-tech">Laravel 9</span>
                                <span className="p-tech">Filament</span>
                                <span className="p-tech">Livewire</span>
                                <span className="p-tech">MySQL</span>
                                <span className="p-tech">Node.js</span>
                                <br />
                                <br />
                                <span><b>Screenshots</b></span>
                                <div className="pr-imgc">
                                    <ImageView src={zhara1} alt="Zhara Events" />
                                    <ImageView src={zhara2} alt="Zhara Events" />
                                    <ImageView src={zhara3} alt="Zhara Events" />
                                    <ImageView src={zhara4} alt="Zhara Events" />
                                    <ImageView src={zhara5} alt="Zhara Events" style={{ maxWidth: "360px" }} />
                                    <ImageView src={zhara6} alt="Zhara Events" style={{ maxWidth: "360px" }} />
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="pr-tm">
                                <span className="pr-dot"></span>
                                2022
                            </div>
                            <div className="pr-cn">
                                <span className="pr-title" href="#">MUSA Finance - Website, Mobile App, API</span><br />
                                <span>Android, iOS App and Web Interface (PWA), Ionic, React front-end with Laravel REST API, MySQL.</span>
                                <br /><br />
                                <b>Technologies</b><br />
                                <span className="p-tech">HTML5</span>
                                <span className="p-tech">CSS3</span>
                                <span className="p-tech">Ionic</span>
                                <span className="p-tech">TypeScript</span>
                                <span className="p-tech">React</span>
                                <span className="p-tech">Laravel 8</span>
                                <span className="p-tech">MySQL</span>
                                <span className="p-tech">Node.js</span>
                                <br />
                                <br />
                                <span><b>Screenshots</b></span>
                                <div className="pr-imgc">
                                    <ImageView src={musa1} style={{ maxWidth: "360px" }} alt="MUSA Personal Finance App" />
                                    <ImageView src={musa2} style={{ maxWidth: "360px" }} alt="MUSA Personal Finance App" />
                                    <ImageView src={musa3} style={{ maxWidth: "360px" }} alt="MUSA Personal Finance App" />
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="pr-tm">
                                <span className="pr-dot"></span>
                                2021
                            </div>
                            <div className="pr-cn">
                                <span className="pr-title" href="#">Hölscher Wasserbau GmbH - website</span><br />
                                <span>Front-end development based on given design and back-end implementation with Pimcore for Hölscher Wasserbau GmbH.</span>
                                <br /><br />
                                <b>Technologies</b><br />
                                <span className="p-tech">HTML5</span>
                                <span className="p-tech">CSS3</span>
                                <span className="p-tech">Bootstrap 4</span>
                                <span className="p-tech">Font Awesome</span>
                                <span className="p-tech">Javascript</span>
                                <span className="p-tech">Jquery</span>
                                <span className="p-tech">Pimcore</span>
                                <span className="p-tech">Twig</span>
                                <br />
                                <br />
                                <span><b>Screenshots</b></span>
                                <div className="pr-imgc">
                                    <ImageView src={hoelscher1} alt={"Hölscher Wasserbau GmbH"} />
                                    <ImageView src={hoelscher2} alt={"Hölscher Wasserbau GmbH"} />
                                    <ImageView src={hoelscher3} alt={"Hölscher Wasserbau GmbH"} />
                                </div>
                            </div>
                        </li>


                        <li>
                            <div className="pr-tm">
                                <span className="pr-dot"></span>
                                2020
                            </div>
                            <div className="pr-cn">
                                <span className="pr-title" href="#">Bruns Umwelt- und Entsorgungstechnik GmbH - website</span><br />
                                <span>Front-end development based on given design and back-end implementation with Pimcore for Bruns Umwelt- und Entsorgungstechnik GmbH.</span>
                                <br /><br />
                                <b>Technologies</b><br />
                                <span className="p-tech">HTML5</span>
                                <span className="p-tech">CSS3</span>
                                <span className="p-tech">Bootstrap 4</span>
                                <span className="p-tech">Font Awesome</span>
                                <span className="p-tech">Javascript</span>
                                <span className="p-tech">Jquery</span>
                                <span className="p-tech">Pimcore</span>
                                <span className="p-tech">Twig</span>
                                <br />
                                <br />
                                <span><b>Screenshots</b></span>
                                <div className="pr-imgc">
                                    <ImageView src={bruns1} alt={"Bruns Umwelt - und Entsorgungstechnik GmbH"} />
                                    <ImageView src={bruns2} alt={"Bruns Umwelt - und Entsorgungstechnik GmbH"} />
                                    <ImageView src={bruns3} alt={"Bruns Umwelt - und Entsorgungstechnik GmbH"} />
                                </div>
                            </div>
                        </li>


                        <li>
                            <div className="pr-tm">
                                <span className="pr-dot"></span>
                                2016-2018
                            </div>
                            <div className="pr-cn">
                                <span className="pr-title" href="#">EAL.az - Online Shopping Platform</span><br />
                                <span>EAL.az is a B2C online shopping platform based in Baku, Azerbaijan. Both of front-end and back-end coded by me, including design and deployment.</span>
                                <br /><br />
                                <b>Technologies</b><br />
                                <span className="p-tech">HTML5</span>
                                <span className="p-tech">CSS3</span>
                                <span className="p-tech">Bootstrap 4</span>
                                <span className="p-tech">Font Awesome</span>
                                <span className="p-tech">Javascript</span>
                                <span className="p-tech">Jquery</span>
                                <span className="p-tech">Pure PHP 7.1</span>
                                <span className="p-tech">Laravel (only Control Panel)</span>
                                <span className="p-tech">MySQL</span>
                                <br />
                                <br />
                                <span><b>Screenshots</b></span>
                                <div className="pr-imgc">
                                    <ImageView src={eal1} alt={"EAL.az"} />
                                    <ImageView src={eal2} alt={"EAL.az"} />
                                    <ImageView src={eal3} alt={"EAL.az"} />
                                    <ImageView src={eal4} alt={"EAL.az"} />
                                    <ImageView src={eal5} alt={"EAL.az"} />
                                    <ImageView src={eal6} alt={"EAL.az"} />
                                    <ImageView src={eal7} alt={"EAL.az"} />
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="pr-tm">
                                <span className="pr-dot"></span>
                                2016
                            </div>
                            <div className="pr-cn">
                                <span className="pr-title" href="#">Aquasol.az - Official website of Aqualsol LLC</span><br />
                                <span>Aquasol LLC is a water purification systems and equipments seller based in Baku, Azerbaijan. Website demonstrates the equipment and systems of the company and some info about them. Everything is done by me, except Design.</span>
                                <br /><br />
                                <b>Technologies</b><br />
                                <span className="p-tech">HTML5</span>
                                <span className="p-tech">CSS3</span>
                                <span className="p-tech">Materialize CSS</span>
                                <span className="p-tech">Javascript</span>
                                <span className="p-tech">Jquery</span>
                                <span className="p-tech">Pure PHP 7.1</span>
                                <span className="p-tech">MySQL</span>
                                <br />
                                <br />
                                <span><b>Screenshots</b></span>
                                <div className="pr-imgc">
                                    <ImageView src={aqs1} alt={"Aquasol.az"} />
                                    <ImageView src={aqs2} alt={"Aquasol.az"} />
                                    <ImageView src={aqs3} alt={"Aquasol.az"} />
                                </div>
                            </div>
                        </li>
                    </ul>

                </div>
            </div>

            <MyEffects />
        </>
    )
}