import React from 'react';
import NavMenu from '../components/NavMenu'
import MyEffects from '../components/MyEffects'

import sk1 from './../assets/sk1.svg';


export default function Skills() {
    return (
        <>
            <NavMenu />

            <div className="sk-right">
                <div className="skr-inner">
                    <h1 className="ir-h">SKILLS</h1>
                    <p className="">I have 6+ years of development experience building fullstack products from scratch, developing and integrating APIs,
                        working with a variety of databases and clould platforms.</p>
                    <p>My job is to take the projects from the <b>idea stage</b> and make them <b>fully working web products</b> by taking it throught required steps: Design, Development, Testing and Deployment.</p>
                    <p>Feel free to ask me any questions. I can help you to bring your awesome web product ideas to life by applying my below listed skills.</p>
                    <img className="sk-img" src={sk1} alt="" />
                    <br /><br />

                    <div className="sk-cc">
                        <h2 className="sk-hh"><i className="fas fa-pencil-ruler"></i> Front-end Web Development</h2>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fab fa-html5"></i> HTML <span className="prog-p">98%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '98%' }}></div>
                            </div>
                        </div>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fab fa-css3-alt"></i> CSS <span className="prog-p">95%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '95%' }}></div>
                            </div>
                        </div>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fab fa-js"></i> Javascript <span className="prog-p">85%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '85%' }}></div>
                            </div>
                        </div>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fab fa-bootstrap"></i> Bootstrap <span className="prog-p">92%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '92%' }}></div>
                            </div>
                        </div>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fab fa-react"></i> React <span className="prog-p">80%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '80%' }}></div>
                            </div>
                        </div>
                    </div>




                    <div className="sk-cc">
                        <h2 className="sk-hh"><i className="fas fa-code"></i> Back-end Web Development</h2>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fab fa-php"></i> PHP <span className="prog-p">94%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '94%' }}></div>
                            </div>
                        </div>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fas fa-database"></i> MySQL <span className="prog-p">96%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '96%' }}></div>
                            </div>
                        </div>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fab fa-laravel"></i> Laravel <span className="prog-p">65%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '65%' }}></div>
                            </div>
                        </div>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fab fa-node-js"></i> Node JS <span className="prog-p">65%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '65%' }}></div>
                            </div>
                        </div>
                    </div>




                    <div className="sk-cc">
                        <h2 className="sk-hh"><i className="fas fa-terminal"></i> Dev-Ops, Development Tools & Others</h2>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fas fa-code-branch"></i> Git & Github<span className="prog-p">96%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '96%' }}></div>
                            </div>
                        </div>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="fab fa-linux"></i> Linux (Cent OS)<span className="prog-p">90%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '90%' }}></div>
                            </div>
                        </div>

                        <div className="sk-prog">
                            <div className="prog-h"><i className="far fa-file-code"></i> Apache <span className="prog-p">97%</span></div>
                            <div className="prog-b">
                                <div style={{ width: '97%' }}></div>
                            </div>
                        </div>

                    </div>



                </div>
            </div>

            <MyEffects />
        </>
    )
}