import React from 'react'
import NavMenu from '../components/NavMenu'
import MyEffects from '../components/MyEffects'


export default function Apps() {
    return (
        <>
            <NavMenu />

            <div className="i-right">
                <div className="ir-inner">
                    <h1 className="ir-h">APPS. FOR FRIENDS.</h1>
                    <div>
                        <p>Nothing to see here...</p>
                    </div>
                </div>
            </div>

            <MyEffects />
        </>
    )
}
