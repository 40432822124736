import React from 'react'
import NavMenu from '../components/NavMenu'
import MyEffects from '../components/MyEffects'


export default function Contact() {
    return (
        <>
            <NavMenu />


            <div className="i-right">
                <div className="ir-inner">
                    <h1 className="ir-h">CONTACT ME</h1>
                    <p className="">I am available for hire and open to any ideas of cooperation. Feel free to reach out to me by using one of the following:</p>
                    <br /><br />

                    <div className="ct-dets">
                        <div className="ct-det">
                            <i className="far fa-envelope"></i>&nbsp;&nbsp;&nbsp; <a href="mailto:mubarizhajimuradov@gmail.com">mubarizhajimuradov@gmail.com</a>
                        </div>
                        <div className="ct-det">
                            <i className="fab fa-linkedin"></i>&nbsp;&nbsp;&nbsp; <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/mubariz-hajimuradov">Mubariz Hajimuradov</a>
                        </div>
                        <div className="ct-det">
                            <i className="fab fa-github"></i>&nbsp;&nbsp;&nbsp; <a target="_blank" rel="noopener noreferrer" href="https://github.com/muba00">muba00</a>
                        </div>
                        <div className="ct-det">
                            <i className="fab fa-facebook-square"></i>&nbsp;&nbsp;&nbsp; <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/hv.mubariz">Mubariz Hajimuradov</a>
                        </div>

                    </div>
                </div>
            </div>

            <MyEffects />
        </>
    )
}