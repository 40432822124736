import React from 'react'


export default function Gunel1() {
    const [counter, setCounter] = React.useState(0)

    const increaseCounter = () => {
        setCounter(counter + 1)
    }

    const resetCounter = () => {
        setCounter(0)
    }

    return (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Merienda:wght@300..900&display=swap" rel="stylesheet" />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <div style={{ padding: '1rem', backgroundColor: '#313131', borderRadius: '16px' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        backgroundColor: 'white',
                        padding: '0.5rem',
                        borderRadius: '0.5rem',
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '1.25rem',
                        minWidth: '180px'
                    }}>{counter}</div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
                        <button onClick={increaseCounter}
                            style={{
                                color: 'white',
                                padding: '0.5rem',
                                borderRadius: '0.5rem',
                                flexGrow: 1,
                                backgroundColor: '#8489FF',
                                height: '70px'
                            }}></button>
                        <button onClick={resetCounter}
                            style={{
                                color: 'white',
                                padding: '0.5rem',
                                marginLeft: '2rem',
                                backgroundColor: '#F95D5D',
                                width: '40px',
                                height: '40px',
                                borderRadius: '120px'
                            }}></button>
                    </div>

                    <div style={{ color: "#ffffff", display: 'flex', justifyContent: 'flex-end', marginTop: '1.5rem', fontFamily: '"Merienda", cursive' }}>
                        Gunel 1
                    </div>
                </div>
            </div>
        </>
    )
}
