import React from 'react'
import { Link } from 'react-router-dom'
import NavMenu from '../components/NavMenu'
import MyEffects from '../components/MyEffects'

export default function Home() {
    return (
        <>
            <NavMenu />

            <div className="h-right">
                <div className="hr-inn">
                    <span className="hr-ha">Hi, I'm Mubariz.</span><br />
                    <span className="hr-hb">I'm a full stack web developer in Emden,<br /> designing and developing web apps is my passion.</span>
                    <br /><br /><br />
                    <Link className="hr-btn" to="/portfolio">Portfolio</Link>
                    <Link className="hr-btn" to="/skills">Skills</Link>
                </div>
            </div>
            <div className="love">
                <span>Made with <i className="fas fa-heart"></i> in Emden</span>
            </div>

            <MyEffects />
        </>
    )
}